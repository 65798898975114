<template>
  <section class="mb-4">
    <b-container>
      <b-row v-if="data && latestArticles">
        <b-col
          v-for="(item, index) in latestArticles.banner_content"
          :key="index"
          md="4"
          class="mb-3"
          ><div class="cy-report-card p-4 h-100" :class="`card-${index + 1}`">
            <p class="cy-font-weight-700 cy-heading-3 card-type mb-2">
              {{ articleTag(item.article_tag) }}
            </p>
            <p class="cy-heading-24 cy-line-height-33 cy-font-weight-700 mb-2">
              {{ item.title }}
            </p>
            <p>
              {{ item.short_desc }}
            </p>
            <a
              :href="item.url"
              target="_blank"
              class="text-decoration-none d-block cy-text-black"
            >
              <p class="pt-2 cy-font-weight-600 cy-heading-5 mb-0">
                {{ item.url_text }}
              </p></a
            >
          </div></b-col
        >
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    latestArticles() {
      return this.data
    },
    articleTag() {
      return (item) => {
        item = item.toLowerCase()
        const words = item.split(' ')
        return words
          .map((word) => {
            return word[0].toUpperCase() + word.substring(1)
          })
          .join(' ')
      }
    },
    deviceCheck() {
      return this.$device.isMobile && this.$device.isMobileOrTablet
        ? 'mobile'
        : 'web'
    }
  }
}
</script>

<style lang="scss" scoped>
.cy-report-card {
  border-radius: 4px;
}
.card-1 {
  background: #ebefff;
  .card-type {
    color: #2853ff;
  }
}
.card-2 {
  background: linear-gradient(112.57deg, #ebf2ff 20.38%, #ffeeee 88.25%);
  .card-type {
    color: #ff1d8e;
  }
}
.card-3 {
  background: #fff8e6;
  .card-type {
    color: #ff6b00;
  }
}
.card-heading {
  width: 65%;
}
</style>
