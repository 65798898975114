// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../static/home/bg_element_plus.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cyber-fusion-card[data-v-69f490b2]{background:#fff;border-radius:10px}@media (min-width:800px){.bg-image[data-v-69f490b2]:after,.bg-image[data-v-69f490b2]:before,.bg-img[data-v-69f490b2]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;content:\"\";height:15%;opacity:.3;position:absolute;width:15%}.bg-image[data-v-69f490b2]{position:relative;z-index:1}.bg-image[data-v-69f490b2]:before{background-position:0 0;left:-10px;top:430px}.bg-image[data-v-69f490b2]:after{right:-3px;top:-7px;transform:rotate(180deg);z-index:-1}}.icon-background[data-v-69f490b2]{background:#eef1f6;border-radius:50%;height:70px;margin:auto;position:relative;width:70px}.icon-background .icon[data-v-69f490b2]{left:25%;position:absolute;top:25%}.cy-platform[data-v-69f490b2]{display:table-cell;vertical-align:middle}.cy-platform__col[data-v-69f490b2]{display:table}.cy-platform__heading[data-v-69f490b2]{color:#f73f7d;font-size:20px}.cy-platform__question[data-v-69f490b2]{color:#35435e;font-size:30px}.cy-platform__description[data-v-69f490b2]{color:#535966}.cyber-fusion-items__heading[data-v-69f490b2]{color:#1a3ee8}.carousel-logos[data-v-69f490b2]{filter:grayscale(100%)}.cyber-fusion__section[data-v-69f490b2]{font-size:34px}.product-heading[data-v-69f490b2]{font-size:3rem}.product-description[data-v-69f490b2]{font-size:1.7rem;margin-right:4rem;margin-top:1.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
