var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-container', {
    staticClass: "cy-section-mb"
  }, [_c('b-row', {
    staticClass: "align-items-center"
  }, [_c('b-col', {
    staticClass: "text-left mb-4 mb-md-0 homepage-heading",
    attrs: {
      "md": "6"
    }
  }, [_c('h1', {
    staticClass: "cy-heading-1 cy-font-weight-800 cy-line-height-xs-30 cy-line-height-40"
  }, [_vm._v("\n          AI-driven Threat Intel and\n        ")]), _vm._v(" "), _c('h1', {
    staticClass: "cy-heading-1 cy-font-weight-800 cy-line-height-xs-30 cy-text-primary-blue cy-line-height-40"
  }, [_vm._v("\n          Cybersecurity Automation\n        ")]), _vm._v(" "), _c('p', {
    staticClass: "cy-heading-4 cy-heading-xs-3"
  }, [_vm._v("\n          Transform security operations, consolidate TIP and SOAR, collaborate\n          seamlessly, and prevent attacks with AI-driven intelligence and\n          orchestrated response.\n        ")]), _vm._v(" "), _c('nuxt-link', {
    staticClass: "text-decoration-none",
    attrs: {
      "to": _vm.getHyperLink('demo-page')
    }
  }, [_c('b-button', {
    staticClass: "cy-btn cy-btn--primary"
  }, [_vm._v(" Request a Demo ")])], 1)], 1), _vm._v(" "), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "md": "6"
    }
  }, [_c('b-img', {
    attrs: {
      "alt": "Cyber Fusion & Threat Intelligence Solution",
      "title": "Cyber Fusion & Threat Intelligence Solution",
      "src": require(`~/static/home/hero_image_homepage.svg`),
      "fluid-grow": "",
      "width": 100,
      "height": 100
    }
  })], 1)], 1)], 1), _vm._v(" "), _c('section', [_c('b-container', [_vm.homepageLogos && _vm.homepageLogos.length > 0 ? _c('div', {
    staticClass: "pb-5"
  }, [_c('VueSlickCarousel', _vm._b({
    staticClass: "custom-slider"
  }, 'VueSlickCarousel', _vm.slider, false), _vm._l(_vm.homepageLogos, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "d-flex align-items-center text-center"
    }, [_c('b-img', {
      staticClass: "mx-auto carousel-logos",
      attrs: {
        "src": require(`~/static/homepage-logos/${item.image}`),
        "alt": item.alt
      }
    })], 1);
  }), 0)], 1) : _vm._e()])], 1), _vm._v(" "), _vm.bannerData && _vm.bannerData['latest-from-cyware'] ? _c('cy-report-banner', {
    attrs: {
      "data": _vm.bannerData['latest-from-cyware'].find(function (x) {
        return x.banner_type_data.banner_type_slug === 'text';
      })
    }
  }) : _vm._e(), _vm._v(" "), _c('section', {
    staticClass: "mb-5"
  }, [_c('b-container', {
    staticClass: "cy-home__card"
  }, [_c('b-row', {
    staticClass: "p-5"
  }, [_c('b-col', {
    staticClass: "cy-platform__col",
    attrs: {
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "cy-platform"
  }, [_c('p', {
    staticClass: "cy-font-weight-600 cy-platform__heading cy-line-height-32 mb-0"
  }, [_vm._v("\n              THE CYWARE PLATFORM\n            ")]), _vm._v(" "), _c('p', {
    staticClass: "cy-font-weight-700 cy-line-height-50 cy-platform__question mb-0"
  }, [_vm._v("\n              What is Cyber Fusion?\n            ")]), _vm._v(" "), _c('p', {
    staticClass: "cy-font-weight-400 cy-heading-xs-1 cy-line-height-28 cy-platform__description"
  }, [_vm._v("\n              More than just a security automation tool, Cyber Fusion unites\n              threat intel and SOAR to automate any security tool, orchestrate\n              any environment, and collaborate across any boundary, to yield\n              more intelligent threat response.\n            ")]), _vm._v(" "), _c('nuxt-link', {
    attrs: {
      "to": _vm.getHyperLink('cfc-solutions')
    }
  }, [_c('b-button', {
    staticClass: "cy-btn cy-btn--outline-primary d-flex align-items-center"
  }, [_c('span', [_vm._v("Learn More")])])], 1)], 1)]), _vm._v(" "), _c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('b-row', {
    staticClass: "bg-image py-3"
  }, [_c('b-col', {
    staticClass: "d-flex text-center mb-4 align-items-stretch",
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "p-4 cyber-fusion-card"
  }, [_c('b-img-lazy', {
    staticClass: "mb-1",
    attrs: {
      "src": require(`~/static/homepage-logos/icon1.svg`),
      "alt": "fusion-icon"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "cy-heading-5 cy-line-height-22"
  }, [_c('span', {
    staticClass: "cy-font-weight-700"
  }, [_vm._v("Collaboration ")]), _vm._v(" "), _c('span', {
    staticClass: "cy-font-weight-400"
  }, [_vm._v("\n                    enhanced by data from every source to drive proactive\n                    response\n                  ")])])], 1)]), _c('b-col', {
    staticClass: "d-flex text-center mb-4 align-items-stretch",
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "p-4 cyber-fusion-card"
  }, [_c('b-img-lazy', {
    staticClass: "mb-1",
    attrs: {
      "src": require(`~/static/homepage-logos/icon2.svg`),
      "alt": "fusion-icon"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "cy-heading-5 cy-line-height-22"
  }, [_c('span', {
    staticClass: "cy-font-weight-700"
  }, [_vm._v("Visibility ")]), _vm._v(" "), _c('span', {
    staticClass: "cy-font-weight-400"
  }, [_vm._v("\n                    gleaned from continuous monitoring, real-time data\n                    collection, correlation, and analysis to yield actionable\n                    insights\n                  ")])])], 1)]), _vm._v(" "), _c('b-col', {
    staticClass: "d-flex text-center mb-4 align-items-stretch",
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "p-4 cyber-fusion-card"
  }, [_c('b-img-lazy', {
    staticClass: "mb-1",
    attrs: {
      "src": require(`~/static/homepage-logos/icon3.svg`),
      "alt": "fusion-icon"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "cy-heading-5 cy-line-height-22"
  }, [_c('span', {
    staticClass: "cy-font-weight-700"
  }, [_vm._v("\n                    Threat Intelligence\n                    "), _c('span', {
    staticClass: "cy-font-weight-400"
  }, [_vm._v("\n                      contextualized to inform, enrich, and power advanced\n                      security")])])])], 1)]), _c('b-col', {
    staticClass: "d-flex text-center align-items-stretch mb-4",
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "p-4 cyber-fusion-card"
  }, [_c('b-img-lazy', {
    staticClass: "mb-1",
    attrs: {
      "src": require(`~/static/homepage-logos/icon4.svg`),
      "alt": "fusion-icon"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "cy-heading-5 cy-line-height-22"
  }, [_c('span', {
    staticClass: "cy-font-weight-700"
  }, [_vm._v("Orchestration ")]), _vm._v(" "), _c('span', {
    staticClass: "cy-font-weight-400"
  }, [_vm._v("\n                    beyond incident detection and response to power informed\n                    actions across the security ecosystem\n                  ")])])], 1)])], 1)], 1)], 1), _vm._v(" "), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "10",
      "offset-md": "1"
    }
  }, [_c('h2', {
    staticClass: "cyber-fusion__section text-center cy-font-weight-600 cy-line-height-36 cy-line-height-42"
  }, [_vm._v("\n            Cyware Quarterback"), _c('br'), _c('span', {
    staticClass: "cy-font-weight-800"
  }, [_vm._v("\n              Your AI Signal Caller for Every Cybersecurity Play\n            ")])])]), _vm._v(" "), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "10",
      "offset-md": "1"
    }
  }, [_c('p', {
    staticClass: "cy-heading-3 cy-line-height-32"
  }, [_vm._v("\n            Learn how Cyware Quarterback’s cutting-edge AI capabilities\n            integrate across your SecOps tech stack, improving your\n            cybersecurity strategy, driving smarter outcomes, and empowering\n            your analysts.\n          ")]), _vm._v(" "), _c('div', {
    class: _vm.play ? '' : 'cy-webinar-play-btn cy-csap-play-icon cursor-pointer',
    on: {
      "click": _vm.playVideo
    }
  }, [_c('video', {
    ref: "prodVideo",
    attrs: {
      "id": "videoElement",
      "poster": require(`~/static/home/cyware-quarterback-ai.jpg`),
      "loop": true,
      "width": "100%",
      "preload": "metadata"
    },
    domProps: {
      "muted": true
    },
    on: {
      "ended": _vm.onEndVideo
    }
  }, [_c('source', {
    attrs: {
      "src": require(`~/static/home/cyware-quarterback-ai.mp4`),
      "type": "video/mp4"
    }
  }), _vm._v("\n              Your browser does not support HTML5 video.\n            ")])])]), _vm._v(" "), _c('b-col', {
    staticClass: "pt-5",
    attrs: {
      "cols": "12",
      "md": "6",
      "offset-md": "3"
    }
  }, [_c('h2', {
    staticClass: "cyber-fusion__section text-center cy-font-weight-600 cy-line-height-42"
  }, [_vm._v("\n            Threat Intel Solutions\n            "), _c('br'), _vm._v(" "), _c('span', {
    staticClass: "cy-font-weight-800"
  }, [_vm._v("\n              Designed for Collaboration\n            ")])])]), _vm._v(" "), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "10",
      "offset-md": "1"
    }
  }, [_c('p', {
    staticClass: "cy-heading-3 cy-line-height-32"
  }, [_vm._v("\n            Multiply your forces for threat detection, analysis, & security\n            incident response by operationalizing high confidence human and\n            machine-processed threat intelligence.\n          ")]), _vm._v(" "), _c('nuxt-link', {
    staticClass: "text-decoration-none",
    attrs: {
      "to": _vm.getHyperLink('demo-page')
    }
  }, [_c('b-button', {
    staticClass: "cy-btn cy-btn--primary"
  }, [_vm._v("\n              Talk to an Expert Now\n            ")])], 1)], 1)], 1), _vm._v(" "), _c('b-row', {
    staticClass: "align-items-center py-md-5 py-3"
  }, [_c('b-col', {
    staticClass: "pl-md-0 mb-4 mb-md-0",
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-img-lazy', {
    attrs: {
      "src": require(`~/static/home/product_feature_image_ctix.svg`),
      "fluid-grow": ""
    }
  })], 1), _vm._v(" "), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-img-lazy', {
    attrs: {
      "src": require(`~/static/homepage-logos/intel-exchange.svg`),
      "width": "60px",
      "height": "66px"
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "product-heading cy-font-weight-800 cy-line-height-38 cy-line-height-xs-40 cyber-fusion-items__heading pl-2"
  }, [_vm._v("\n              Intel Exchange\n            ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "product-description cy-heading-xs-3 mb-3"
  }, [_vm._v("\n            An advanced threat intelligence platform (TIP) for automated\n            ingestion, enrichment, and analysis of threat indicators (IOC)\n            across collaborative and bidirectional sharing ecosystems.\n          ")]), _vm._v(" "), _c('ul', {
    staticClass: "cy-heading-5 list-unstyled"
  }, [_c('li', {
    staticClass: "mb-3 d-flex align-items-center"
  }, [_c('b-img-lazy', {
    attrs: {
      "src": require(`~/static/home/fully_automated_threat_intel_operations_ctix.svg`)
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "pl-2 w-75"
  }, [_vm._v("\n                Fully automated cybersecurity threat intel operations\n              ")])], 1), _vm._v(" "), _c('li', {
    staticClass: "mb-3 d-flex align-items-center"
  }, [_c('b-img-lazy', {
    attrs: {
      "src": require(`~/static/home/bidirectional_sharing_and_analysis_(STIX 2.x Compliant)_ctix.svg`)
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "pl-2"
  }, [_vm._v("\n                Bidirectional sharing and analysis (STIX 2.1 Compliant)\n              ")])], 1)]), _vm._v(" "), _c('a', {
    staticClass: "d-inline-block mt-3",
    attrs: {
      "href": _vm.getHyperLink('ctix'),
      "target": "_blank"
    }
  }, [_c('b-button', {
    staticClass: "cy-btn cy-btn--outline-primary"
  }, [_vm._v("\n              Learn More\n            ")])], 1)])], 1), _vm._v(" "), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr', {
    staticClass: "cy-product__dash-border"
  })])], 1), _vm._v(" "), _c('b-row', {
    staticClass: "align-items-center py-md-5 py-3"
  }, [_c('b-col', {
    staticClass: "pl-md-5 order-2 order-md-0",
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-img-lazy', {
    attrs: {
      "src": require(`~/static/homepage-logos/communicate.svg`)
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "product-heading cy-font-weight-800 cy-line-height-38 cy-line-height-xs-40 cyber-fusion-items__heading pl-2"
  }, [_vm._v("\n              Collaborate\n            ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "product-description cy-heading-xs-3 mb-3"
  }, [_vm._v("\n            Cyware’s cybersecurity automation platform automates security\n            alert aggregation and advisory sharing into one platform designed\n            to drive real-time situational awareness, expedite potential\n            threat information exchange, and foster collaboration between\n            security teams.\n          ")]), _vm._v(" "), _c('ul', {
    staticClass: "cy-heading-5 list-unstyled"
  }, [_c('li', {
    staticClass: "mb-3 d-flex align-items-center"
  }, [_c('b-img-lazy', {
    attrs: {
      "src": require(`~/static/home/multi_source_alert_and_situational_intel_aggregation_csap.svg`)
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "pl-2"
  }, [_vm._v("\n                Multi-source alerting, including mobile, for situational intel\n                aggregation\n              ")])], 1), _vm._v(" "), _c('li', {
    staticClass: "mb-3 d-flex align-items-center"
  }, [_c('b-img-lazy', {
    attrs: {
      "src": require(`~/static/home/threat_advisory_marketplace_and_real_time_sharing_csap.svg`)
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "pl-2"
  }, [_vm._v("\n                Real-time advisory sharing on cybersecurity threats across any\n                defined ecosystem\n              ")])], 1)]), _vm._v(" "), _c('a', {
    staticClass: "d-inline-block mt-3",
    attrs: {
      "href": _vm.getHyperLink('csap'),
      "target": "_blank"
    }
  }, [_c('b-button', {
    staticClass: "cy-btn cy-btn--outline-primary"
  }, [_vm._v("\n              Learn More\n            ")])], 1)]), _vm._v(" "), _c('b-col', {
    staticClass: "pr-md-0 mb-4 mb-md-0 order-1 order-md-0 pb-4 mt-3",
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-img-lazy', {
    attrs: {
      "src": require(`~/static/home/product_feature_image_csap.svg`),
      "fluid-grow": ""
    }
  })], 1)], 1), _vm._v(" "), _c('b-row', {
    staticClass: "align-items-center py-md-5 pt-3 pb-5"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "offset-md": "3"
    }
  }, [_c('h2', {
    staticClass: "cyber-fusion__section text-center cy-font-weight-600 cy-line-height-42"
  }, [_vm._v("\n            SOAR Solutions\n            "), _c('br'), _vm._v(" "), _c('span', {
    staticClass: "cy-font-weight-800"
  }, [_vm._v("\n              Built for Low-Code Automation\n            ")])])]), _vm._v(" "), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "10",
      "offset-md": "1"
    }
  }, [_c('p', {
    staticClass: "cy-heading-3 cy-line-height-32"
  }, [_vm._v("\n            Security Orchestration and Automation Decoupled from Response\n            (SOA+R) to enhance case management and extend orchestration\n            utility to all teams, assets, and tools.\n          ")]), _vm._v(" "), _c('nuxt-link', {
    staticClass: "text-decoration-none",
    attrs: {
      "to": _vm.getHyperLink('demo-page')
    }
  }, [_c('b-button', {
    staticClass: "cy-btn cy-btn--primary"
  }, [_vm._v("\n              Schedule a Free POC\n            ")])], 1)], 1)], 1), _vm._v(" "), _c('b-row', {
    staticClass: "align-items-center pb-md-5 py-3"
  }, [_c('b-col', {
    staticClass: "pl-md-0 mb-4 mb-md-0",
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-img-lazy', {
    attrs: {
      "src": require(`~/static/home/product_feature_image_cftr.svg`),
      "fluid-grow": ""
    }
  })], 1), _vm._v(" "), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-img-lazy', {
    attrs: {
      "src": require(`~/static/homepage-logos/respond.svg`),
      "width": "60px",
      "height": "66px"
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "product-heading cy-font-weight-800 cy-line-height-38 cy-line-height-xs-40 cyber-fusion-items__heading pl-2"
  }, [_vm._v("\n              Respond\n            ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "product-description cy-heading-xs-3 mb-3"
  }, [_vm._v("\n            An automated case management and intelligent security incident\n            response platform – powered by AI – that connects all data,\n            enables collaboration, extends data usability, and delivers\n            broader insight into cybersecurity threats and incidents.\n          ")]), _vm._v(" "), _c('ul', {
    staticClass: "cy-heading-5 list-unstyled"
  }, [_c('li', {
    staticClass: "mb-3 d-flex align-items-start"
  }, [_c('b-img-lazy', {
    attrs: {
      "src": require(`~/static/home/malware_vulnerability_threat_actor_and_incident_case_management_cftr.svg`)
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "pl-2 w-75"
  }, [_vm._v("\n                Drive more thorough analytics to yield richer insights and\n                greater visibility\n              ")])], 1), _vm._v(" "), _c('li', {
    staticClass: "mb-3 d-flex align-items-center"
  }, [_c('b-img-lazy', {
    attrs: {
      "src": require(`~/static/home/connect_the_dots.svg`)
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "pl-2"
  }, [_vm._v("\n                Enable security operations, threat intel, vulnerability\n                management, and incident response\n              ")])], 1)]), _vm._v(" "), _c('a', {
    staticClass: "d-inline-block mt-3",
    attrs: {
      "href": _vm.getHyperLink('cftr'),
      "target": "_blank"
    }
  }, [_c('b-button', {
    staticClass: "cy-btn cy-btn--outline-primary"
  }, [_vm._v("\n              Learn More\n            ")])], 1)])], 1), _vm._v(" "), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr', {
    staticClass: "cy-product__dash-border"
  })])], 1), _vm._v(" "), _c('b-row', {
    staticClass: "align-items-center py-md-5 py-3"
  }, [_c('b-col', {
    staticClass: "pl-md-5 order-2 order-md-0",
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-img-lazy', {
    attrs: {
      "src": require(`~/static/homepage-logos/orchestrate.svg`),
      "width": "60px",
      "height": "66px"
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "product-heading cy-font-weight-800 cy-line-height-38 cy-line-height-xs-40 cyber-fusion-items__heading pl-2"
  }, [_vm._v("\n              Orchestrate\n            ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "product-description cy-heading-xs-3 mb-3"
  }, [_vm._v("\n            A vendor-agnostic, low-code automation platform for connecting and\n            orchestrating cyber, IT, and DevOps workflows across cloud,\n            on-premises, and hybrid environments.\n          ")]), _vm._v(" "), _c('ul', {
    staticClass: "cy-heading-5 list-unstyled"
  }, [_c('li', {
    staticClass: "mb-3 d-flex align-items-center"
  }, [_c('b-img-lazy', {
    attrs: {
      "src": require(`~/static/home/low_code_custom_automation_150_pre_built_playbook_templates_cyware_orchestrate.svg`)
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "pl-2"
  }, [_vm._v("\n                Customizable workflow automation; 150+ pre-built playbook\n                templates\n              ")])], 1), _vm._v(" "), _c('li', {
    staticClass: "mb-3 d-flex align-items-center"
  }, [_c('b-img-lazy', {
    attrs: {
      "src": require(`~/static/home/app_marketplace_with_300_integrations_cyware_orchestrate.svg`)
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "pl-2"
  }, [_vm._v("\n                App Marketplace with 300+ integrations\n              ")])], 1)]), _vm._v(" "), _c('a', {
    staticClass: "d-inline-block mt-3",
    attrs: {
      "href": _vm.getHyperLink('cyware-orchestrate'),
      "target": "_blank"
    }
  }, [_c('b-button', {
    staticClass: "cy-btn cy-btn--outline-primary"
  }, [_vm._v("\n              Learn More\n            ")])], 1)]), _vm._v(" "), _c('b-col', {
    staticClass: "pr-md-0 mb-4 mb-md-0 order-1 order-md-0 pb-5",
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-img-lazy', {
    attrs: {
      "src": require(`~/static/home/product_feature_image_cywareorchestrate11.svg`),
      "fluid-grow": ""
    }
  })], 1)], 1)], 1)], 1), _vm._v(" "), _c('section', [_c('b-container', {
    staticClass: "cy-home__card cy-home__integrations pb-5"
  }, [_c('b-row', {
    staticClass: "py-5"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "8",
      "offset-md": "2"
    }
  }, [_c('h2', {
    staticClass: "cyber-fusion__section text-center cy-font-weight-600 cy-line-height-42"
  }, [_vm._v("\n            Powering the World's Most\n            "), _c('br'), _vm._v(" "), _c('span', {
    staticClass: "cy-font-weight-800"
  }, [_vm._v("\n              Capable Threat Sharing Communities\n            ")])])]), _vm._v(" "), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "10",
      "offset-md": "1"
    }
  }, [_c('p', {
    staticClass: "cy-heading-3"
  }, [_vm._v("\n            Over 85% of global ISACs and ISAOs use Cyware to share threat\n            intelligence with their members. As an ISAC member you already\n            have access to this intelligence and now can extend collaboration\n            within your organization.\n          ")]), _vm._v(" "), _c('nuxt-link', {
    staticClass: "d-inline-block mt-3",
    attrs: {
      "to": _vm.getHyperLink('isac-iso-certs'),
      "target": "_blank"
    }
  }, [_c('b-button', {
    staticClass: "cy-btn cy-btn--primary"
  }, [_vm._v(" Learn More ")])], 1)], 1), _vm._v(" "), _c('b-col', {
    staticClass: "cy-home__integrations-logopanel my-4",
    attrs: {
      "cols": "11"
    }
  }, [_c('b-row', {
    staticClass: "align-items-center"
  }, [_c('b-col', {
    staticClass: "p-3 pt-4",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-row', {
    staticClass: "py-3 mx-0"
  }, _vm._l(_vm.appsLogos, function (item, index) {
    return _c('b-col', {
      key: index,
      staticClass: "p-0 mb-4",
      attrs: {
        "cols": "6",
        "md": "2"
      }
    }, [_c('b-img-lazy', {
      attrs: {
        "src": require(`~/static/isaac-logos/${item.image}`),
        "alt": "Cyware Integrations",
        "width": "200%",
        "height": "120"
      }
    })], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c('video-modal', {
    attrs: {
      "source-link": "https://www.youtube-nocookie.com/embed/HGR6jy19XP4?controls=1&autoplay=1"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }