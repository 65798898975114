var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "mb-4"
  }, [_c('b-container', [_vm.data && _vm.latestArticles ? _c('b-row', _vm._l(_vm.latestArticles.banner_content, function (item, index) {
    return _c('b-col', {
      key: index,
      staticClass: "mb-3",
      attrs: {
        "md": "4"
      }
    }, [_c('div', {
      staticClass: "cy-report-card p-4 h-100",
      class: `card-${index + 1}`
    }, [_c('p', {
      staticClass: "cy-font-weight-700 cy-heading-3 card-type mb-2"
    }, [_vm._v("\n            " + _vm._s(_vm.articleTag(item.article_tag)) + "\n          ")]), _vm._v(" "), _c('p', {
      staticClass: "cy-heading-24 cy-line-height-33 cy-font-weight-700 mb-2"
    }, [_vm._v("\n            " + _vm._s(item.title) + "\n          ")]), _vm._v(" "), _c('p', [_vm._v("\n            " + _vm._s(item.short_desc) + "\n          ")]), _vm._v(" "), _c('a', {
      staticClass: "text-decoration-none d-block cy-text-black",
      attrs: {
        "href": item.url,
        "target": "_blank"
      }
    }, [_c('p', {
      staticClass: "pt-2 cy-font-weight-600 cy-heading-5 mb-0"
    }, [_vm._v("\n              " + _vm._s(item.url_text) + "\n            ")])])])]);
  }), 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }