<template>
  <section>
    <b-container class="cy-section-mb">
      <b-row class="align-items-center">
        <b-col md="6" class="text-left mb-4 mb-md-0 homepage-heading">
          <h1
            class="cy-heading-1 cy-font-weight-800 cy-line-height-xs-30 cy-line-height-40"
          >
            AI-driven Threat Intel and
          </h1>
          <h1
            class="cy-heading-1 cy-font-weight-800 cy-line-height-xs-30 cy-text-primary-blue cy-line-height-40"
          >
            Cybersecurity Automation
          </h1>
          <p class="cy-heading-4 cy-heading-xs-3">
            Transform security operations, consolidate TIP and SOAR, collaborate
            seamlessly, and prevent attacks with AI-driven intelligence and
            orchestrated response.
          </p>
          <nuxt-link
            :to="getHyperLink('demo-page')"
            class="text-decoration-none"
          >
            <b-button class="cy-btn cy-btn--primary"> Request a Demo </b-button>
          </nuxt-link>
        </b-col>
        <b-col md="6" class="text-center">
          <b-img
            alt="Cyber Fusion & Threat Intelligence Solution"
            title="Cyber Fusion & Threat Intelligence Solution"
            :src="require(`~/static/home/hero_image_homepage.svg`)"
            fluid-grow
            :width="100"
            :height="100"
          />
        </b-col>
      </b-row>
    </b-container>
    <section>
      <b-container>
        <div v-if="homepageLogos && homepageLogos.length > 0" class="pb-5">
          <VueSlickCarousel v-bind="slider" class="custom-slider">
            <div
              v-for="(item, index) in homepageLogos"
              :key="index"
              class="d-flex align-items-center text-center"
            >
              <b-img
                :src="require(`~/static/homepage-logos/${item.image}`)"
                :alt="item.alt"
                class="mx-auto carousel-logos"
              />
            </div>
          </VueSlickCarousel>
        </div>
      </b-container>
    </section>

    <cy-report-banner
      v-if="bannerData && bannerData['latest-from-cyware']"
      :data="
        bannerData['latest-from-cyware'].find(
          (x) => x.banner_type_data.banner_type_slug === 'text'
        )
      "
    ></cy-report-banner>

    <section class="mb-5">
      <b-container class="cy-home__card">
        <b-row class="p-5">
          <b-col md="5" class="cy-platform__col"
            ><div class="cy-platform">
              <p
                class="cy-font-weight-600 cy-platform__heading cy-line-height-32 mb-0"
              >
                THE CYWARE PLATFORM
              </p>
              <p
                class="cy-font-weight-700 cy-line-height-50 cy-platform__question mb-0"
              >
                What is Cyber Fusion?
              </p>
              <p
                class="cy-font-weight-400 cy-heading-xs-1 cy-line-height-28 cy-platform__description"
              >
                More than just a security automation tool, Cyber Fusion unites
                threat intel and SOAR to automate any security tool, orchestrate
                any environment, and collaborate across any boundary, to yield
                more intelligent threat response.
              </p>
              <nuxt-link :to="getHyperLink('cfc-solutions')">
                <b-button
                  class="cy-btn cy-btn--outline-primary d-flex align-items-center"
                >
                  <span>Learn More</span>
                </b-button>
              </nuxt-link>
            </div>
          </b-col>
          <b-col md="7">
            <b-row class="bg-image py-3"
              ><b-col
                md="6"
                cols="12"
                class="d-flex text-center mb-4 align-items-stretch"
              >
                <div class="p-4 cyber-fusion-card">
                  <b-img-lazy
                    :src="require(`~/static/homepage-logos/icon1.svg`)"
                    alt="fusion-icon"
                    class="mb-1"
                  />
                  <div class="cy-heading-5 cy-line-height-22">
                    <span class="cy-font-weight-700">Collaboration </span>
                    <span class="cy-font-weight-400">
                      enhanced by data from every source to drive proactive
                      response
                    </span>
                  </div>
                </div> </b-col
              ><b-col
                md="6"
                cols="12"
                class="d-flex text-center mb-4 align-items-stretch"
                ><div class="p-4 cyber-fusion-card">
                  <b-img-lazy
                    :src="require(`~/static/homepage-logos/icon2.svg`)"
                    alt="fusion-icon"
                    class="mb-1"
                  />
                  <div class="cy-heading-5 cy-line-height-22">
                    <span class="cy-font-weight-700">Visibility </span>
                    <span class="cy-font-weight-400">
                      gleaned from continuous monitoring, real-time data
                      collection, correlation, and analysis to yield actionable
                      insights
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col
                md="6"
                cols="12"
                class="d-flex text-center mb-4 align-items-stretch"
                ><div class="p-4 cyber-fusion-card">
                  <b-img-lazy
                    :src="require(`~/static/homepage-logos/icon3.svg`)"
                    alt="fusion-icon"
                    class="mb-1"
                  />
                  <div class="cy-heading-5 cy-line-height-22">
                    <span class="cy-font-weight-700">
                      Threat Intelligence
                      <span class="cy-font-weight-400">
                        contextualized to inform, enrich, and power advanced
                        security</span
                      >
                    </span>
                  </div>
                </div></b-col
              ><b-col
                md="6"
                cols="12"
                class="d-flex text-center align-items-stretch mb-4"
                ><div class="p-4 cyber-fusion-card">
                  <b-img-lazy
                    :src="require(`~/static/homepage-logos/icon4.svg`)"
                    alt="fusion-icon"
                    class="mb-1"
                  />
                  <div class="cy-heading-5 cy-line-height-22">
                    <span class="cy-font-weight-700">Orchestration </span>
                    <span class="cy-font-weight-400">
                      beyond incident detection and response to power informed
                      actions across the security ecosystem
                    </span>
                  </div>
                </div>
              </b-col></b-row
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="10" offset-md="1">
            <h2
              class="cyber-fusion__section text-center cy-font-weight-600 cy-line-height-36 cy-line-height-42"
            >
              Cyware Quarterback<br /><span class="cy-font-weight-800">
                Your AI Signal Caller for Every Cybersecurity Play
              </span>
            </h2>
          </b-col>
          <b-col cols="12" md="10" offset-md="1" class="text-center">
            <p class="cy-heading-3 cy-line-height-32">
              Learn how Cyware Quarterback’s cutting-edge AI capabilities
              integrate across your SecOps tech stack, improving your
              cybersecurity strategy, driving smarter outcomes, and empowering
              your analysts.
            </p>
            <div
              :class="
                play
                  ? ''
                  : 'cy-webinar-play-btn cy-csap-play-icon cursor-pointer'
              "
              @click="playVideo"
            >
              <video
                id="videoElement"
                ref="prodVideo"
                :poster="require(`~/static/home/cyware-quarterback-ai.jpg`)"
                :muted="true"
                :loop="true"
                width="100%"
                preload="metadata"
                @ended="onEndVideo"
              >
                <source
                  :src="require(`~/static/home/cyware-quarterback-ai.mp4`)"
                  type="video/mp4"
                />
                Your browser does not support HTML5 video.
              </video>
            </div>
          </b-col>
          <b-col cols="12" md="6" offset-md="3" class="pt-5">
            <h2
              class="cyber-fusion__section text-center cy-font-weight-600 cy-line-height-42"
            >
              Threat Intel Solutions
              <br />
              <span class="cy-font-weight-800">
                Designed for Collaboration
              </span>
            </h2>
          </b-col>
          <b-col cols="12" md="10" offset-md="1" class="text-center">
            <p class="cy-heading-3 cy-line-height-32">
              Multiply your forces for threat detection, analysis, & security
              incident response by operationalizing high confidence human and
              machine-processed threat intelligence.
            </p>
            <nuxt-link
              :to="getHyperLink('demo-page')"
              class="text-decoration-none"
            >
              <b-button class="cy-btn cy-btn--primary">
                Talk to an Expert Now
              </b-button>
            </nuxt-link></b-col
          >
        </b-row>
        <b-row class="align-items-center py-md-5 py-3">
          <b-col md="6" cols="12" class="pl-md-0 mb-4 mb-md-0">
            <b-img-lazy
              :src="require(`~/static/home/product_feature_image_ctix.svg`)"
              fluid-grow
            />
          </b-col>
          <b-col md="6" cols="12">
            <div class="d-flex align-items-center">
              <b-img-lazy
                :src="require(`~/static/homepage-logos/intel-exchange.svg`)"
                width="60px"
                height="66px"
              />
              <span
                class="product-heading cy-font-weight-800 cy-line-height-38 cy-line-height-xs-40 cyber-fusion-items__heading pl-2"
              >
                Intel Exchange
              </span>
            </div>
            <div class="product-description cy-heading-xs-3 mb-3">
              An advanced threat intelligence platform (TIP) for automated
              ingestion, enrichment, and analysis of threat indicators (IOC)
              across collaborative and bidirectional sharing ecosystems.
            </div>
            <ul class="cy-heading-5 list-unstyled">
              <li class="mb-3 d-flex align-items-center">
                <b-img-lazy
                  :src="
                    require(`~/static/home/fully_automated_threat_intel_operations_ctix.svg`)
                  "
                />
                <span class="pl-2 w-75">
                  Fully automated cybersecurity threat intel operations
                </span>
              </li>
              <li class="mb-3 d-flex align-items-center">
                <b-img-lazy
                  :src="
                    require(`~/static/home/bidirectional_sharing_and_analysis_(STIX 2.x Compliant)_ctix.svg`)
                  "
                />
                <span class="pl-2">
                  Bidirectional sharing and analysis (STIX 2.1 Compliant)
                </span>
              </li>
            </ul>
            <a
              :href="getHyperLink('ctix')"
              target="_blank"
              class="d-inline-block mt-3"
            >
              <b-button class="cy-btn cy-btn--outline-primary">
                Learn More
              </b-button>
            </a>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr class="cy-product__dash-border" />
          </b-col>
        </b-row>
        <b-row class="align-items-center py-md-5 py-3">
          <b-col md="6" cols="12" class="pl-md-5 order-2 order-md-0">
            <div class="d-flex align-items-center">
              <b-img-lazy
                :src="require(`~/static/homepage-logos/communicate.svg`)"
              />
              <span
                class="product-heading cy-font-weight-800 cy-line-height-38 cy-line-height-xs-40 cyber-fusion-items__heading pl-2"
              >
                Collaborate
              </span>
            </div>
            <div class="product-description cy-heading-xs-3 mb-3">
              Cyware’s cybersecurity automation platform automates security
              alert aggregation and advisory sharing into one platform designed
              to drive real-time situational awareness, expedite potential
              threat information exchange, and foster collaboration between
              security teams.
            </div>
            <ul class="cy-heading-5 list-unstyled">
              <li class="mb-3 d-flex align-items-center">
                <b-img-lazy
                  :src="
                    require(`~/static/home/multi_source_alert_and_situational_intel_aggregation_csap.svg`)
                  "
                />
                <span class="pl-2">
                  Multi-source alerting, including mobile, for situational intel
                  aggregation
                </span>
              </li>
              <li class="mb-3 d-flex align-items-center">
                <b-img-lazy
                  :src="
                    require(`~/static/home/threat_advisory_marketplace_and_real_time_sharing_csap.svg`)
                  "
                />
                <span class="pl-2">
                  Real-time advisory sharing on cybersecurity threats across any
                  defined ecosystem
                </span>
              </li>
            </ul>
            <a
              :href="getHyperLink('csap')"
              target="_blank"
              class="d-inline-block mt-3"
            >
              <b-button class="cy-btn cy-btn--outline-primary">
                Learn More
              </b-button>
            </a>
          </b-col>
          <b-col
            md="6"
            cols="12"
            class="pr-md-0 mb-4 mb-md-0 order-1 order-md-0 pb-4 mt-3"
          >
            <b-img-lazy
              :src="require(`~/static/home/product_feature_image_csap.svg`)"
              fluid-grow
            />
          </b-col>
        </b-row>
        <b-row class="align-items-center py-md-5 pt-3 pb-5">
          <b-col cols="12" md="6" offset-md="3">
            <h2
              class="cyber-fusion__section text-center cy-font-weight-600 cy-line-height-42"
            >
              SOAR Solutions
              <br />
              <span class="cy-font-weight-800">
                Built for Low-Code Automation
              </span>
            </h2>
          </b-col>
          <b-col cols="12" md="10" offset-md="1" class="text-center">
            <p class="cy-heading-3 cy-line-height-32">
              Security Orchestration and Automation Decoupled from Response
              (SOA+R) to enhance case management and extend orchestration
              utility to all teams, assets, and tools.
            </p>
            <nuxt-link
              :to="getHyperLink('demo-page')"
              class="text-decoration-none"
            >
              <b-button class="cy-btn cy-btn--primary">
                Schedule a Free POC
              </b-button>
            </nuxt-link></b-col
          >
        </b-row>
        <b-row class="align-items-center pb-md-5 py-3">
          <b-col md="6" cols="12" class="pl-md-0 mb-4 mb-md-0">
            <b-img-lazy
              :src="require(`~/static/home/product_feature_image_cftr.svg`)"
              fluid-grow
            />
          </b-col>
          <b-col md="6" cols="12">
            <div class="d-flex align-items-center">
              <b-img-lazy
                :src="require(`~/static/homepage-logos/respond.svg`)"
                width="60px"
                height="66px"
              />
              <span
                class="product-heading cy-font-weight-800 cy-line-height-38 cy-line-height-xs-40 cyber-fusion-items__heading pl-2"
              >
                Respond
              </span>
            </div>
            <div class="product-description cy-heading-xs-3 mb-3">
              An automated case management and intelligent security incident
              response platform – powered by AI – that connects all data,
              enables collaboration, extends data usability, and delivers
              broader insight into cybersecurity threats and incidents.
            </div>
            <ul class="cy-heading-5 list-unstyled">
              <li class="mb-3 d-flex align-items-start">
                <b-img-lazy
                  :src="
                    require(`~/static/home/malware_vulnerability_threat_actor_and_incident_case_management_cftr.svg`)
                  "
                />
                <span class="pl-2 w-75">
                  Drive more thorough analytics to yield richer insights and
                  greater visibility
                </span>
              </li>
              <li class="mb-3 d-flex align-items-center">
                <b-img-lazy
                  :src="require(`~/static/home/connect_the_dots.svg`)"
                />
                <span class="pl-2">
                  Enable security operations, threat intel, vulnerability
                  management, and incident response
                </span>
              </li>
            </ul>
            <a
              :href="getHyperLink('cftr')"
              target="_blank"
              class="d-inline-block mt-3"
            >
              <b-button class="cy-btn cy-btn--outline-primary">
                Learn More
              </b-button>
            </a>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr class="cy-product__dash-border" />
          </b-col>
        </b-row>
        <b-row class="align-items-center py-md-5 py-3">
          <b-col md="6" cols="12" class="pl-md-5 order-2 order-md-0">
            <div class="d-flex align-items-center">
              <b-img-lazy
                :src="require(`~/static/homepage-logos/orchestrate.svg`)"
                width="60px"
                height="66px"
              />
              <span
                class="product-heading cy-font-weight-800 cy-line-height-38 cy-line-height-xs-40 cyber-fusion-items__heading pl-2"
              >
                Orchestrate
              </span>
            </div>
            <div class="product-description cy-heading-xs-3 mb-3">
              A vendor-agnostic, low-code automation platform for connecting and
              orchestrating cyber, IT, and DevOps workflows across cloud,
              on-premises, and hybrid environments.
            </div>
            <ul class="cy-heading-5 list-unstyled">
              <li class="mb-3 d-flex align-items-center">
                <b-img-lazy
                  :src="
                    require(`~/static/home/low_code_custom_automation_150_pre_built_playbook_templates_cyware_orchestrate.svg`)
                  "
                />
                <span class="pl-2">
                  Customizable workflow automation; 150+ pre-built playbook
                  templates
                </span>
              </li>
              <li class="mb-3 d-flex align-items-center">
                <b-img-lazy
                  :src="
                    require(`~/static/home/app_marketplace_with_300_integrations_cyware_orchestrate.svg`)
                  "
                />
                <span class="pl-2">
                  App Marketplace with 300+ integrations
                </span>
              </li>
            </ul>
            <a
              :href="getHyperLink('cyware-orchestrate')"
              target="_blank"
              class="d-inline-block mt-3"
            >
              <b-button class="cy-btn cy-btn--outline-primary">
                Learn More
              </b-button>
            </a>
          </b-col>
          <b-col
            md="6"
            cols="12"
            class="pr-md-0 mb-4 mb-md-0 order-1 order-md-0 pb-5"
          >
            <b-img-lazy
              :src="
                require(`~/static/home/product_feature_image_cywareorchestrate11.svg`)
              "
              fluid-grow
            />
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section>
      <b-container class="cy-home__card cy-home__integrations pb-5">
        <b-row class="py-5">
          <b-col cols="12" md="8" offset-md="2">
            <h2
              class="cyber-fusion__section text-center cy-font-weight-600 cy-line-height-42"
            >
              Powering the World's Most
              <br />
              <span class="cy-font-weight-800">
                Capable Threat Sharing Communities
              </span>
            </h2>
          </b-col>
          <b-col cols="12" md="10" offset-md="1" class="text-center">
            <p class="cy-heading-3">
              Over 85% of global ISACs and ISAOs use Cyware to share threat
              intelligence with their members. As an ISAC member you already
              have access to this intelligence and now can extend collaboration
              within your organization.
            </p>
            <nuxt-link
              :to="getHyperLink('isac-iso-certs')"
              target="_blank"
              class="d-inline-block mt-3"
            >
              <b-button class="cy-btn cy-btn--primary"> Learn More </b-button>
            </nuxt-link>
          </b-col>
          <b-col cols="11" class="cy-home__integrations-logopanel my-4">
            <b-row class="align-items-center">
              <b-col md="12" cols="12" class="p-3 pt-4">
                <b-row class="py-3 mx-0">
                  <b-col
                    v-for="(item, index) in appsLogos"
                    :key="index"
                    cols="6"
                    md="2"
                    class="p-0 mb-4"
                  >
                    <b-img-lazy
                      :src="require(`~/static/isaac-logos/${item.image}`)"
                      alt="Cyware Integrations"
                      width="200%"
                      height="120"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <video-modal
      source-link="https://www.youtube-nocookie.com/embed/HGR6jy19XP4?controls=1&autoplay=1"
    />
  </section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import { APPS, LOGOS } from '@/config/communities-logos'
import ALL_MENUS from '@/config/menuData'
import CyReportBanner from '@/components/banners/CyReportBanner.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  components: {
    VueSlickCarousel,
    CyReportBanner,
    VideoModal: () => import('@/components/miscellaneous/VideoModal')
  },
  data() {
    return {
      menus: ALL_MENUS?.solutions?.children[0]?.children,
      appsLogos: APPS,
      homepageLogos: LOGOS,
      timer: null,
      play: false,
      slider: {
        dots: false,
        focusOnSelect: false,
        infinite: true,
        speed: 6000,
        arrows: false,
        slidesToShow: 6,
        autoplay: true,
        cssEase: 'linear',
        accessibility: false,
        draggable: false,
        swipe: false,
        touchMove: false,
        pauseOnDotsHover: false,
        pauseOnFocus: false,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  },
  methods: {
    setupVideo() {
      const v = document.getElementById('videoElement')
      v.addEventListener(
        'mouseover',
        function () {
          this.controls = true
        },
        false
      )
      v.addEventListener(
        'mouseout',
        function () {
          this.controls = false
        },
        false
      )
      v.addEventListener(
        'mousemove',
        function () {
          this.controls = true
        },
        false
      )
    },

    playVideo() {
      this.$refs.prodVideo.play()
      this.play = true
      this.setupVideo()
    },
    onEndVideo(evt) {
      this.$refs.prodVideo.load()
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.custom-slider {
  .slick-track {
    display: flex !important;
    align-items: center !important;
  }
}
</style>
<style lang="scss" scoped>
.cyber-fusion-card {
  background: #ffffff;
  border-radius: 10px;
}
@media (min-width: 800px) {
  .bg-img {
    height: 15%;
    width: 15%;
    content: '';
    background-image: url('~static/home/bg_element_plus.svg');
    position: absolute;
    opacity: 0.3;
    background-repeat: no-repeat;
  }
  .bg-image {
    position: relative;
    z-index: 1;
    &::before {
      @extend .bg-img;
      background-position: 0 0;
      left: -10px;
      top: 430px;
    }
    &::after {
      @extend .bg-img;
      top: -7px;
      right: -3px;
      z-index: -1;
      transform: rotate(180deg);
    }
  }
}

.icon-background {
  width: 70px;
  height: 70px;
  background: #eef1f6;
  border-radius: 50%;
  margin: auto;
  position: relative;
  .icon {
    position: absolute;
    top: 25%;
    left: 25%;
  }
}
.cy-platform {
  display: table-cell;
  vertical-align: middle;
  &__col {
    display: table;
  }
  &__heading {
    font-size: 20px;
    color: #f73f7d;
  }
  &__question {
    font-size: 30px;
    color: #35435e;
  }
  &__description {
    color: #535966;
  }
}
.cyber-fusion-items {
  &__heading {
    color: #1a3ee8;
  }
}
.carousel-logos {
  filter: grayscale(100%);
}
.cyber-fusion__section {
  font-size: 34px;
}
.product-heading {
  font-size: 3rem;
}
.product-description {
  margin-top: 1.5rem;
  margin-right: 4rem;
  font-size: 1.7rem;
}
</style>
