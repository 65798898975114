export const APPS = [
  {
    image: '001_h-isac.jpg'
  },
  {
    image: '002_space-isac.jpg'
  },
  {
    image: '003_e-isac.jpg'
  },
  {
    image: '004_ms-isac.jpg'
  },
  {
    image: '005_auto-isac.jpg'
  },
  {
    image: '006_national-isac.jpg'
  },
  {
    image: '007_aviation-isac.jpg'
  },
  {
    image: '008_mts-isac.jpg'
  },
  {
    image: '009_ren-isac.jpg'
  },
  {
    image: '010_me-isac.jpg'
  },
  {
    image: '011_ei-isac.jpg'
  },
  {
    image: '012_l-isac.jpg'
  },
  {
    image: '013_dng-isac.jpg'
  },
  {
    image: '014_nrf.jpg'
  },
  {
    image: '015_ot-isac.jpg'
  },
  {
    image: '016_ease.jpg'
  },
  {
    image: '017_k12six.jpg'
  },
  {
    image: '018_mgf-isac.jpg'
  }
]
export const LOGOS = [
  {
    image: 'guidepointsecurity_cyware_partner.svg',
    alt: 'aws'
  },
  {
    image: 'arvest.svg',
    alt: 'Arvest'
  },
  {
    image: 'H-ISAC.svg',
    alt: 'H-ISAC'
  },
  {
    image: 'Optiv.svg',
    alt: 'Optiv'
  },
  {
    image: 'SHI.svg',
    alt: 'SHI'
  },
  {
    image: 'Morado.svg',
    alt: 'Morado'
  },
  {
    image: 'aviation_isac.svg',
    alt: 'Aviation Isac'
  },
  {
    image: 'Presidio.svg',
    alt: 'Presidio'
  },
  {
    image: 'PWC.svg',
    alt: 'PWC'
  }
]
